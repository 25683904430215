import React from 'react';
import './App.css';
import Navbar from './components/navbar'
import NavDrawer from './components/navDrawer'
import Menu from './components/menu'
import Checkout from './components/checkout'
import Voucher from './components/voucher'
import ReservationPage from './components/reservation/ReservationPage'
import ProductDetails from './components/ProductDetails';

import Theme from './themeConfig'
import backgroundImage from './asset/paperBackground.jpg'
import { GoogleMapsProvider } from './context/gmapscontext'
import { UserProvider } from './context/userContext'
import { CatalogProvider } from './context/catalogContext'
import { OrderProvider } from './context/orderContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Toolbar, ThemeProvider, StyledEngineProvider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 240

const styles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    flex: 1,
    minHeight: '100vh',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundImage: `url(${backgroundImage})`,
  }
}))
const Root = ({ children }) => {
  const classes = styles()
  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}
const Content = ({ children }) => {
  const classes = styles()
  return (
    <div className={classes.content}>
      {children}
    </div>
  )
}

function App() {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <OrderProvider>
          <CatalogProvider>
            <GoogleMapsProvider>
              <UserProvider>
                <Root>
                  <Router>
                    <Navbar
                      drawerWidth={drawerWidth}
                      onClickMenuButton={() => setIsOpenDrawer(!isOpenDrawer)} />
                    <NavDrawer
                      drawerWidth={drawerWidth}
                      open={isOpenDrawer}
                      onClose={() => setIsOpenDrawer(!isOpenDrawer)}
                    />
                    <Content>
                      <Toolbar />
                      <Routes>
                        <Route exact path='/' element={<Menu />} />
                        <Route path='checkout/' element={<Checkout />} />
                        <Route path='voucher/:token/' element={<Voucher />} />
                        <Route path='order/:buyOrder/' element={<Voucher />} />
                        <Route path='reservation/' element={<ReservationPage />} />
                        <Route path='product/:id' element={<ProductDetails />} />
                      </Routes>
                    </Content>
                  </Router>
                </Root>
              </UserProvider>
            </GoogleMapsProvider>
          </CatalogProvider>
        </OrderProvider>
      </ThemeProvider >
    </StyledEngineProvider >
  );
}

export default App;
