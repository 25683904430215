import React from 'react'

export const OrderContext = React.createContext()

export const OrderProvider = props => {

    const [order, setOrder] = React.useState({ Order_Items: [], status: 'initialized' })

    return (
        <OrderContext.Provider value={{ order, setOrder }}>
            {props.children}
        </OrderContext.Provider>
    )
}