import React from 'react'
import axios from 'axios'
import CONSTANTS from '../config'

export const CatalogContext = React.createContext()

export const CatalogProvider = props => {

    const [catalog, setCatalog] = React.useState()
    React.useEffect(() => {
        const fetchCatalog = async () => {
            const catalog = await axios({
                baseURL: CONSTANTS.BACKEND_BASE_URL,
                url: `/api/category`,
            })
            setCatalog(catalog.data)
        }
        fetchCatalog()
    }, [])

    return (
        <CatalogContext.Provider value={catalog}>
            {props.children}
        </CatalogContext.Provider>
    )
}