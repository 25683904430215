import React from "react";
import { Box } from "@mui/material";
import axios from "axios";
import CONSTANTS from "../config";
import { GoogleMapsContext } from "../context/gmapscontext";
import { UserContext } from "../context/userContext";

export default (props) => {
  const isGoogleMapsLoaded = React.useContext(GoogleMapsContext);
  const { userAddress } = React.useContext(UserContext);
  const [radius, setRadius] = React.useState(0);
  React.useEffect(() => {
    const getStore = async () => {
      try {
        const store = await axios({
          method: "get",
          url: `${CONSTANTS.BACKEND_BASE_URL}/api/store/1`,
        });
        setRadius(store.data.deliveryRadius);
      } catch (error) {
        alert(error);
      }
    };
    getStore();
  }, []);
  React.useEffect(() => {
    if (isGoogleMapsLoaded && radius) {
      var pi = { lat: -33.4376397, lng: -70.6158242 };
      var map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 11,
        center: pi,
      });
      new window.google.maps.Circle({
        center: pi,
        map: map,
        radius: radius,
      });
      var icon = {
        url: "https://storage.googleapis.com/product-gallery/logo.png",
        scaledSize: new window.google.maps.Size(32, 32),
      };
      var pimarker = new window.google.maps.Marker({
        title: "Pizzería Italia",
        map: map,
        visible: true,
        icon: icon,
      });
      pimarker.setPosition(pi);
      if ("fullAddress" in userAddress) {
        console.log("addressDisplay", userAddress);
        var clientMarker = new window.google.maps.Marker({
          title: "Pizzería Italia",
          map: map,
          visible: true,
        });
        clientMarker.setPosition(userAddress);
      }
    }
  }, [isGoogleMapsLoaded, userAddress, radius]);

  return (
    <Box style={{ padding: 0 }}>
      <Box
        id="map"
        style={{
          marginTop: 16,
          minHeight: 300,
          width: 500,
        }}
      />
    </Box>
  );
};
