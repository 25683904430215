import React from "react";
import { makeStyles } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import { Typography } from "@mui/material";

const product = {
    id: 1,
    title: 'Pizza Margarita',
    images: [
      'https://via.placeholder.com/350x150',
      'https://via.placeholder.com/350x150',
      'https://via.placeholder.com/350x150',
    ],
    shortDescription: 'Pizza tradicional italiana con salsa de tomate, queso mozzarella y albahaca fresca.',
    longDescription: 'La pizza Margarita es una de las pizzas más tradicionales de la cocina italiana. Su sencillez y sabor la convierten en una de las pizzas más populares en todo el mundo. Esta pizza se elabora con una base de masa de pizza cubierta con salsa de tomate, queso mozzarella y hojas frescas de albahaca. La combinación de estos ingredientes crea un sabor clásico y delicioso que gusta a todo el mundo.',
    nutritionFacts: ['Calorías: 250 kcal', 'Proteínas: 10 g', 'Grasas: 12 g', 'Carbohidratos: 30 g'],
    price: 10.99,
  };
  

const useStyles = makeStyles((theme) => ({
  productDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  productDetailsDescriptionShort: {
    margin: "20px 0",
  },
  productDetailsDescriptionLong: {
    margin: "20px 0",
  },
  productDetailsNutrition: {
    margin: "20px 0",
  },
  productDetailsNutritionTitle: {
    marginBottom: "10px",
  },
  carousel: {
    width: "100%",
  },
}));

export default function ProductDetails(props) {
  const classes = useStyles();

  return (
    <div className={classes.productDetails}>
      <Typography variant="h1">{product.title}</Typography>
      <Carousel className={classes.carousel}>
        {product.images.map((image, index) => (
          <img key={index} src={image} alt={`Image ${index}`} />
        ))}
      </Carousel>
      <div className={classes.productDetailsDescriptionShort}>
        <Typography variant="h3">Descripción corta:</Typography>
        <Typography variant="body1">{product.shortDescription}</Typography>
      </div>
      <div className={classes.productDetailsDescriptionLong}>
        <Typography variant="h3">Descripción larga:</Typography>
        <Typography variant="body1">{product.longDescription}</Typography>
      </div>
      <div className={classes.productDetailsNutrition}>
        <Typography variant="h3" className={classes.productDetailsNutritionTitle}>
          Valor nutricional:
        </Typography>
        <ul>
          {product.nutritionFacts.map((fact, index) => (
            <li key={index}>{fact}</li>
          ))}
        </ul>
      </div>
      <Typography variant="h2">Precio: ${product.price}</Typography>
    </div>
  );
}
