import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons'
import {
  Link,
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  appBar: props => ({
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: props.drawerWidth,
    }
  }),
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  rightBox: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  navIcons: {
    //marginRight: theme.spacing(4),
    fontSize: 20,
  },
}));

export default function MenuAppBar(props) {
  const classes = useStyles(props)
  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color='inherit'
            onClick={props.onClickMenuButton}
            size="large">
            <MenuIcon />
          </IconButton>
          <Hidden mdUp><Link style={{ color: 'white', textDecoration: 'none' }} to='/'>Pizzería Italia</Link></Hidden>
          <Hidden mdDown><Typography variant='h6'>Auténtica Receta Italiana</Typography></Hidden>
          <Box className={classes.rightBox}>
            <IconButton
              color='inherit'
              href='https://www.instagram.com/pizzeriaitaliastgo'
              target="_blank"
              size="large">
              <FontAwesomeIcon className={classes.navIcons} icon={faInstagram} />
            </IconButton>
            <IconButton
              color='inherit'
              href='https://www.facebook.com/pizzeriaitaliastgo'
              target="_blank"
              size="large">
              <FontAwesomeIcon className={classes.navIcons} icon={faFacebookF} />
            </IconButton>
            <IconButton color='inherit' href='tel:+56228959808' size="large">
              <FontAwesomeIcon className={classes.navIcons} icon={faPhone} />
            </IconButton>
            <IconButton
              color='inherit'
              href='https://goo.gl/maps/n4BrsaYuMNxNBejd6'
              target="_blank"
              size="large">
              <FontAwesomeIcon className={classes.navIcons} icon={faMapMarkerAlt} />
            </IconButton>
            {/*<IconButton color='inherit'
              onClick={() => { }}>
              <Badge badgeContent={1} color='secondary'>
                <ShoppingCartRoundedIcon />
              </Badge>
            </IconButton>*/}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}