import React from 'react'

export const UserContext = React.createContext({})

export const UserProvider = props => {
    const [userAddress, setUserAddress] = React.useState({})
    return (
        <UserContext.Provider value={{ userAddress, setUserAddress }}>
            {props.children}
        </UserContext.Provider>
    )
}