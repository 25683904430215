import React, { createContext } from "react";

export const GoogleMapsContext = createContext()

export const GoogleMapsProvider = (props) => {

    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = React.useState(false)

    React.useEffect(() => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object' && typeof window.google.maps.places === 'object') {
            setIsGoogleMapsLoaded(true)
        } else {
            let script = document.createElement("script");
            script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQHRmHf8iN-4ww6c-BV7TaDT0qcbVyZuA&libraries=places";
            script.async = true;
            script.id = 'google-maps'
            script.onload = () => setIsGoogleMapsLoaded(true)
            document.body.appendChild(script);
        }
    }, [isGoogleMapsLoaded])

    return (
        <GoogleMapsContext.Provider value={isGoogleMapsLoaded}>
            { props.children}
        </GoogleMapsContext.Provider >
    )
}
