import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import TimePicker from '@mui/lab/TimePicker'
import TextField from '@mui/material/TextField';


const ReservationPage = () => {
  const [value, setValue] = React.useState(new Date())
  const [time, setTime] = React.useState()
  const maxTime = new Date(0, 0, 0, 20, 0, 0)
  const minTime = new Date(0, 0, 0, 13, 0, 0)
  const availableHours = [
    '13:00',
    '15:30:',
    '17:00',

  ]

  const handleChange = (newValue) => {
    setValue(newValue)
  }
  const handleChangeTime = (newValue) => {
    setTime(newValue)
  }
  const shouldDisableDate = (day) => {
    const date = new Date(day)
    return date.getDay() === 0 || date.getDay() === 6
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label="Fecha de Reserva"
        inputFormat="dd/MM/yyyy"
        showTodayButton
        shouldDisableDate={shouldDisableDate}
        minDate={new Date()}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
      />
      <TimePicker
        label="Time"
        value={time}
        onChange={handleChangeTime}
        minutesStep={120}
        maxTime={maxTime}
        minTime={minTime}
        shouldDisableTime={(time) => time.getHours() < 13}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}
export default ReservationPage