import React from "react";
import {
  Typography,
  Box,
  Card,
  FormGroup,
  CardContent,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Button,
  IconButton,
  Badge,
  Paper,
  CardMedia,
  Switch,
  FormControl,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { CatalogContext } from "../context/catalogContext";

//import { OrderContext } from '../context/orderContext'

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  priceTitlePanel: {
    textAlign: "right",
  },
  media: {
    height: 400,
    backgroundSize: "contain",
  },
  priceText: {
    alignSelf: "center",
  },
});
//const containsAll = (arr1, arr2) =>arr2.every(arr2Item => arr1.includes(arr2Item))
const addThousandsSeparator = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
const getInitialVariant = (variants) => {
  variants = variants.filter((variant) => variant.active);
  const maxVariant = variants.find(
    (v) => parseInt(v.price) === Math.max(...variants.map((v) => v.price))
  );
  return maxVariant;
};
export default function (props) {
  if (!props.product) return null;
  const { order, orderIndex } = props;
  const classes = useStyles();
  const catalog = React.useContext(CatalogContext);
  const [mode, setMode] = React.useState(props.mode);
  const product = catalog.reduce((acc, cur) => {
    const findedProduct = cur.Products.find((p) => p.idProduct === props.product.idProduct);
    return findedProduct ? findedProduct : acc;
  }, {});

  const [currentItem, setcurrentItem] = React.useState({
    Variant: orderIndex
      ? order.Order_Items[orderIndex].Variant
      : { ...getInitialVariant(product.Variants), Product: product },
    Addition_Items: order.Order_Items[orderIndex]?.Addition_Items.length
      ? order.Order_Items[orderIndex].Addition_Items
      : [],
    quantity: 1,
    modifier: "",
    total: 0,
  });

  //total variable is calculated in every render
  const total =
    (currentItem.Variant.price +
      currentItem.Addition_Items.reduce((acc, cur) => acc + cur.price, 0)) *
    currentItem.quantity;
  return (
    <>
      {mode === "display" ? (
        <Paper>
          <Card>
            <CardContent>
              <Badge
                badgeContent={
                  <IconButton onClick={() => props.onClose()} aria-label="close" size="large">
                    <CloseIcon style={{ fontSize: 40 }} overlap="circle" />
                  </IconButton>
                }
              >
                <Box>
                  {console.log("product", product)}
                  <CardMedia
                    className={classes.media}
                    image={product.Product_Images[0]?.publicUrl}
                    title={currentItem.name}
                  />
                  <Typography gutterBottom variant="h5" component="h2">
                    {currentItem.name}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" component="p">
                    {product.desc}
                  </Typography>
                </Box>
              </Badge>
            </CardContent>
            <Button
              className={classes.checkoutBar}
              variant="contained"
              style={{ color: "white" }}
              onClick={() => setMode("cart")}
              fullWidth
              children={<Typography>Pedir</Typography>}
              size="large"
              color="secondary"
            />
          </Card>
        </Paper>
      ) : (
        // ****Cart Mode ***///
        <Card>
          <CardContent>
            <Badge
              badgeContent={
                <IconButton onClick={() => props.onClose()} aria-label="close" size="large">
                  <CloseIcon style={{ fontSize: 40 }} overlap="circle" />
                </IconButton>
              }
            >
              <Box p={2}>
                <Box pb={2}>
                  <Typography gutterBottom variant="h5" component="h2" align="center">
                    {currentItem.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    {currentItem.desc}
                  </Typography>
                </Box>
                <FormLabel component="legend">Escoge una opción</FormLabel>
                <RadioGroup
                  aria-label="variant"
                  name="variant"
                  value={currentItem.Variant.name}
                  onChange={(e) =>
                    setcurrentItem({
                      ...currentItem,
                      Variant: {
                        ...product.Variants.find((p) => p.name === e.target.value),
                        Product: product,
                      },
                      Addition_Items: [],
                    })
                  }
                >
                  {product.Variants.map((variant) =>
                    variant.active && !variant.hidden ? (
                      <Box key={variant.name} display="flex" justifyContent="space-between">
                        <FormControlLabel
                          value={variant.name}
                          control={<Radio />}
                          label={variant.name}
                        />
                        <Typography className={classes.priceText}>
                          {`$${addThousandsSeparator(variant.price)}`}
                        </Typography>
                      </Box>
                    ) : null
                  )}
                </RadioGroup>
                {product.Variants.find(
                  (v) => v.idVariant === currentItem.Variant.idVariant
                ).Additions.map(
                  (
                    addition,
                    index //Addition Section, customer can select one or more options that adds to Total
                  ) => (
                    <Box key={addition.name}>
                      {index === 0 ? (
                        <FormLabel component="legend">Escoge Agregados</FormLabel>
                      ) : null}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box display="flex" style={{ flex: 1 }} justifyContent="space-between">
                            <Typography>{addition?.name}</Typography>
                            <Typography>{`$${addThousandsSeparator(
                              addition?.Addition_Items[0]?.price
                            )}`}</Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup row>
                            {addition.Addition_Items.filter((opt) => opt.active).map((opt) => (
                              <FormControlLabel
                                key={opt.idAdditionItems.toString()}
                                control={
                                  <Checkbox
                                    checked={
                                      currentItem.Addition_Items.find(
                                        (a) => a.name === opt.name
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      if (false) {
                                        //there are single and multiple selection
                                        setcurrentItem({
                                          ...currentItem,
                                          Addition_Items: [
                                            {
                                              name: e.target.name,
                                              addition: addition.name,
                                              value: opt.price,
                                            },
                                          ],
                                        });
                                      } else {
                                        if (e.target.checked) {
                                          const updatedAddition =
                                            currentItem.Addition_Items.concat(opt);
                                          setcurrentItem({
                                            ...currentItem,
                                            Addition_Items: updatedAddition,
                                          });
                                        } else {
                                          const updatedAddition = currentItem.Addition_Items.filter(
                                            (addition) => e.target.name !== addition.name
                                          );
                                          setcurrentItem({
                                            ...currentItem,
                                            Addition_Items: updatedAddition,
                                          });
                                        }
                                      }
                                    }}
                                    icon={<RadioButtonUncheckedRoundedIcon />}
                                    checkedIcon={<RadioButtonCheckedRoundedIcon />}
                                    name={opt.name}
                                  />
                                }
                                label={opt.name}
                              />
                            ))}
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )
                )}
                <Box my={1} display="flex" justifyContent="center" alignItems="center">
                  <FormControl style={{ alignItems: "center" }}>
                    <FormLabel component="legend"></FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={currentItem.modifier === "precooked"}
                          onChange={(e) =>
                            setcurrentItem({
                              ...currentItem,
                              modifier: e.target.checked ? "precooked" : "",
                            })
                          }
                          name="modifier"
                        />
                      }
                      label="Precocida"
                    />
                    <FormHelperText>
                      Calienta de 5 a 7 minutos a máxima potencia en tu horno y disfruta tu pizza
                      como recién hecha!.
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box my={1} display="flex" justifyContent="center" alignItems="center">
                  <FormLabel component="legend">Selecciona la Cantidad</FormLabel>
                  <IconButton
                    onClick={() =>
                      currentItem.quantity <= 1
                        ? currentItem.quantity
                        : setcurrentItem({ ...currentItem, quantity: currentItem.quantity - 1 })
                    }
                    size="large"
                  >
                    <RemoveIcon fontSize="large" />
                  </IconButton>
                  <Typography>{currentItem.quantity}</Typography>
                  <IconButton
                    onClick={() =>
                      currentItem.quantity >= 1
                        ? setcurrentItem({ ...currentItem, quantity: currentItem.quantity + 1 })
                        : currentItem.quantity
                    }
                    size="large"
                  >
                    <AddIcon fontSize="large" />
                  </IconButton>
                </Box>

                <Box my={1} display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography style={{ fontWeight: "bold" }}>
                    {`Total: ${addThousandsSeparator(total)}`}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ color: "white", fontWeight: "bold" }}
                    fullWidth
                    children={
                      props.orderIndex !== undefined ? "Modificar Producto" : "Agregar al Carrito"
                    }
                    onClick={() => {
                      if (props.orderIndex !== undefined) {
                        props.setOrder({
                          ...props.order,
                          Order_Items: props.order.Order_Items.map((ord, idx) =>
                            idx === props.orderIndex
                              ? { ...ord, ...currentItem, total: total }
                              : ord
                          ),
                        });
                      } else {
                        props.setOrder({
                          ...props.order,
                          Order_Items: [
                            ...props.order.Order_Items,
                            { ...currentItem, total: total },
                          ],
                        });
                      }
                      props.onClose();
                    }}
                  />
                </Box>
                {props.orderIndex !== undefined ? (
                  <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                    <IconButton
                      onClick={() => {
                        props.setOrder({
                          ...props.order,
                          Order_Items: props.order.Order_Items.filter(
                            (ord, idx) => idx !== props.orderIndex
                          ),
                        });
                        props.onClose();
                      }}
                      size="large"
                    >
                      <DeleteIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>
            </Badge>
          </CardContent>
        </Card>
      )}
    </>
  );
}
